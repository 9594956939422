import React from 'react';
import { graphql } from 'gatsby';

import {
  TopicByIdQuery,
  // eslint-disable-next-line import/extensions,import/no-unresolved
} from '../../types/graphql-types';
import { Header1 } from './headers';
import Layout from './layout';
import SEO from './seo';
import ContentfulRichText from './contentfulRichText';
import PostList from './postList';

type Props = {
  data: TopicByIdQuery;
};

const CategoryPage: React.FC<Props> = ({ data }: Props) => {
  const name = data?.contentfulPostTopic?.name || 'General';
  const blogPosts =
    data?.contentfulPostTopic?.blog_post
      ?.map((post) => {
        if (!post) {
          return {
            id: 'none',
            header: 'none',
            slug: 'none',
            createdAt: 'none',
            createdAtTimestamp: 0,
            publishedTimestamp: 0,
          };
        }
        return post;
      })
      .filter((post) => post.id !== 'none') || [];

  return (
    <Layout pathname="TODO: add pathname">
      <SEO title={`Uhuru Solidarity Movement: ${name}`} />
      <div className="container mx-auto pt-16">
        <Header1
          color="black"
          mobileColorsFlipped
          className="mb-10 mx-5 md:mx-0 uppercase"
        >
          {name}
        </Header1>
        <div className="md:max-w-screen-sm text-base text-white md:text-black mb-16">
          <ContentfulRichText
            document={data?.contentfulPostTopic?.description}
          />
        </div>

        <PostList postList={blogPosts} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query TopicById($id: String!) {
    contentfulPostTopic(topicId: { eq: $id }) {
      blog_post {
        id
        header
        slug
        photoDesktop: photo {
          gatsbyImageData(layout: CONSTRAINED, height: 400, width: 750)
        }
        photoMobile: photo {
          gatsbyImageData(
            layout: CONSTRAINED
            aspectRatio: 1.75
            width: 1280
            cropFocus: CENTER
            placeholder: BLURRED
          )
        }
        author {
          name
        }
        published(formatString: "MMMM Do, YYYY")
        createdAt(formatString: "MMMM Do, YYYY")
        publishedTimestamp: published(formatString: "X")
        createdAtTimestamp: createdAt(formatString: "X")
      }
      name
      description {
        raw
      }
    }
  }
`;

export default CategoryPage;
